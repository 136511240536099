import React from "react"

import styled from "styled-components"
import { Col, Row } from "reactstrap"
import "bootstrap/dist/css/bootstrap.css"
import "./index.css"

import Mailchimp from "react-mailchimp-form"
import Layout from "../components/layout"
import Bg from "../images/bg.jpg"
import Logo from "../images/libraryflix-logo.png"


const BgImage = styled.div`
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-color: #fff2ef;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    overflow: hidden;
    background-image: url(${Bg});
    @media (max-width: 766px) {
        height: 350px;
    }
`
const LogoImage = styled.div`
`
const RightContent = styled.div`
    padding-top: 30vh;
    padding-bottom: 30px;
    min-height: 100vh;
    padding-right: 0px;
    text-align: center !important;
    @media (max-width: 766px) {
        padding-top: 70px;
    }
`
const ComingTitle = styled.div`
    position: relative;
    margin-top: 10vh;
    margin-bottom: 5vh;
`
const ComingPara = styled.p`
    font-size: 17px;
    font-weight: 500;
    color: #000000;
    line-height: 2;
`

const IndexPage = () => (
    <Layout>
        <Row>
            <Col lg="6" md="6"> <BgImage/> </Col>
            <Col lg="6" md="6">
                <RightContent>
                    <LogoImage><img src={Logo} width="100%" alt="logo" /></LogoImage>
                    <h1>Coming Soon!</h1>
                    <ComingTitle>
                        <ComingPara>
                            We are preparing something amazing and exciting for you.
                            <br />
                            Special surprise for our subscribers.
                        </ComingPara>

                        <Mailchimp
                            action="https://libraryflix.us12.list-manage.com/subscribe/post?u=5b21b364f8e05f74653d5520c&amp;id=d36c793611"
                            //Adding multiple fields:
                            fields={[
                                {
                                    name: "EMAIL",
                                    placeholder: "Email",
                                    type: "email",
                                    required: true,
                                },
                            ]}
                            // Change predetermined language
                            messages={{
                                sending: "Sending...",
                                success: "Thank you for subscribing!",
                                error:
                                    "An unexpected internal error has occurred.",
                                empty: "You must write an e-mail.",
                                duplicate:
                                    "Too many subscribe attempts for this email address",
                                button: "Subscribe!",
                            }}
                            // Add a personalized class
                            className="custom-form"
                        />
                    </ComingTitle>
                </RightContent>
            </Col>
        </Row>
    </Layout>
)

export default IndexPage
